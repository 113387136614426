import axios from "axios";

export async function createFinancialNote(financialNote) {
  return await axios.post(`/api/financial-note/create`, { ...financialNote });
}

export async function editFinancialNote(financialNote, financialNoteId) {
  return await axios.put(`/api/financial-note/${financialNoteId}`, {
    ...financialNote,
  });
}

export async function fetchFinancialNotes(patientId) {
  return await axios.get(`/api/financial-note/${patientId}/list`);
}

export async function deleteFinancialNote(financialNoteId) {
  return await axios.delete(`/api/financial-note/${financialNoteId}`);
}
