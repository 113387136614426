import { connect } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { toast } from "react-toastify";
import {
  createFinancialNote,
  deleteFinancialNote,
  editFinancialNote,
  fetchFinancialNotes,
} from "../../../api/financialNotes";
import EditorFroala from "../../editor/EditorFroala";
import DeleteDialog from "../../dialog/DeleteDialog";
import DialogComponent from "../../dialog/Dialog";
import { Description, GetApp } from "@material-ui/icons";
import Axios from "axios";

function FinancialNotePage({ patientId, selectedPatient }) {
  const [financialNotes, setFinancialNotes] = useState([]);
  const [financialNote, setFinancialNote] = useState({
    _id: "",
    title: "",
    model: "",
  });
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openBackupDialog, setOpenBackupDialog] = useState(false);
  const files = selectedPatient?.files?.financialNotes ?? [];

  useEffect(() => {
    fetchData();
  }, []);

  const handleGetFile = async (fileName) => {
    try {
      const response = await Axios({
        url: `/api/patient/${patientId}/files/financial-note/${fileName}`,
        method: "GET",
        responseType: "blob",
      });
      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      link.click();
    } catch (error) {
      toast.error("Error ao obter arquivos");
    }
  };

  const fetchData = async () => {
    try {
      const { data } = await fetchFinancialNotes(patientId);
      if (!data) return;
      setFinancialNotes(data);
    } catch (err) {
      toast.error("Erro ao buscar o acompanhamento financeiro");
    }
  };

  const handleCloseDialog = () => {
    setOpenFormDialog(false);
    setFinancialNote({ title: "", model: "" });
  };

  const handleEditFinancialNote = (note) => {
    setFinancialNote(note);
    setOpenFormDialog(true);
  };

  const handleDeleteDialog = (note) => {
    setFinancialNote(note);
    setOpenDeleteDialog(true);
  };

  const onSubmit = async () => {
    financialNote.patientId = patientId;

    try {
      if (financialNote._id) {
        await editFinancialNote(financialNote, financialNote._id);
      } else {
        delete financialNote._id;
        await createFinancialNote(financialNote);
      }
      fetchData();
      toast.success("Salvo com sucesso!");
      handleCloseDialog();
    } catch (err) {
      toast.error("Erro ao salvar");
    }
  };

  const handleDelete = async () => {
    try {
      await deleteFinancialNote(financialNote._id);
      fetchData();
      setOpenDeleteDialog(false);
      setFinancialNote({ title: "", model: "" });
      toast.success("Nota deletada com sucesso!");
    } catch (err) {
      toast.error("Erro ao remover");
    }
  };

  return (
    <>
      <Container maxWidth="md" elevation={0}>
        <Box padding="16px 16px" display="flex" justifyContent="space-between">
          <Typography variant="h6">Acompanhamento Financeiro</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" marginBottom="12px">
          <Button
            variant="contained"
            disableElevation
            onClick={() => setOpenBackupDialog(true)}
          >
            Backup Dental Manager
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => setOpenFormDialog(true)}
          >
            Novo
          </Button>
        </Box>
        {financialNotes.length &&
          financialNotes.map((note, index) => (
            <Box marginBottom="12px" key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{note.title}</Typography>
                  <Typography variant="subtitle1">
                    {moment(note.date).format("DD/MM/YYYY")}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    {note.body}
                  </Typography>
                  <FroalaEditorView model={note.model} />
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => handleEditFinancialNote(note)}
                  >
                    Editar
                  </Button>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => handleDeleteDialog(note)}
                  >
                    Excluir
                  </Button>
                </CardActions>
              </Card>
            </Box>
          ))}
      </Container>

      {openFormDialog && (
        <Dialog open={openFormDialog}>
          <DialogTitle>
            {financialNote._id ? "Editar nota" : "Nova Nota"}
          </DialogTitle>
          <DialogContent>
            <TextField
              variant="outlined"
              label="Título"
              size="small"
              fullWidth
              value={financialNote.title}
              onChange={(e) =>
                setFinancialNote({ ...financialNote, title: e.target.value })
              }
            />
            <EditorFroala
              model={financialNote.model}
              onModelChange={(model) =>
                setFinancialNote({ ...financialNote, model })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Fechar</Button>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={onSubmit}
            >
              {financialNote._id ? "Salvar" : "Criar"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openDeleteDialog && (
        <DeleteDialog
          open={openDeleteDialog}
          handleClose={() => setOpenDeleteDialog(false)}
          handleSubmit={() => handleDelete()}
        />
      )}
      {openBackupDialog && (
        <DialogComponent
          title="Backup acompanhamento financeiro"
          onClose={() => setOpenBackupDialog(false)}
        >
          <List>
            {files.length ? (
              files.map(({ filename }, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText primary={filename} />
                  <ListItemSecondaryAction
                    onClick={() => handleGetFile(filename)}
                  >
                    <IconButton>
                      <GetApp />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            ) : (
              <DialogContentText>Nenhum arquivo de backup!</DialogContentText>
            )}
          </List>
        </DialogComponent>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const selectedPatient = state.patientsCreation.selectedPatient;

  return {
    selectedPatient,
  };
}

export default connect(mapStateToProps, {})(FinancialNotePage);
