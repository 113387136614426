import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

export default function DialogComponent({
  title,
  children,
  onSubmit,
  buttonTitle,
  onClose,
}) {
  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
        {onSubmit && (
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={onSubmit}
          >
            {buttonTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
