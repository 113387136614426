import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import LoadingButton from "../../buttons/LoadingButton";
import { Delete, Remove } from "@material-ui/icons";

const options = [
  { label: "Prontuário", value: "medical-record" },
  { label: "Ficha endodôntica", value: "endodontic" },
  { label: "Procedimentos da agenda", value: "schedule-procedure" },
  { label: "Agenda", value: "schedule" },
  { label: "Estoque", value: "material" },
  { label: "Imagens", value: "images" },
  { label: "Acompanhamento clínico", value: "clinical-note" },
  { label: "Acompanhamento Financeiro", value: "financial-note" },
  { label: "Backup Dental Manager", value: "backup" },
  { label: "Orçamento", value: "budget" },
];

export default function ClinicUploadAll() {
  const [route, setRoute] = useState(options[0].value);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [multipleFiles, setMultipleFiles] = useState(false);

  const handleChangeOption = (e) => {
    const value = e.target.value;
    if (
      value === "clinical-note" ||
      value === "financial-note" ||
      value === "images" ||
      value === "backup" ||
      value === "budget"
    )
      setMultipleFiles(true);

    setRoute(e.target.value);
  };

  const handleChangeFile = (e) => {
    setFiles([...e.target.files]);
    setIsDisabled(false);
  };

  const handleRemoveFile = (index) => {
    const copyFiles = [...files];
    copyFiles.splice(index, 1);
    setFiles(copyFiles);
    if (!copyFiles.length) setIsDisabled(true);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();

      files.forEach((file) => {
        formData.append("file", file);
      });

      await axios.post(`/api/imports/${route}`, formData);

      toast.success("Importado com sucesso");
      setIsDisabled(true);
      setFiles([]);
    } catch (e) {
      toast.error(e);
    }
    setIsLoading(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>Importador</Typography>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={12}>
          <TextField
            select
            label="Módulo"
            value={route}
            variant="outlined"
            onChange={handleChangeOption}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          {files.length ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome do arquivo</TableCell>
                    <TableCell>Tamanho</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.map((file, index) => (
                    <TableRow key={index}>
                      <TableCell>{file.name}</TableCell>
                      <TableCell>{(file.size / 1024).toFixed(2)}kB</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleRemoveFile(index)}>
                          <Delete color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Button
              component="label"
              variant="outlined"
              size="small"
              onChange={handleChangeFile}
            >
              <input type="file" multiple={multipleFiles} hidden />
              Buscar arquivo
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          title="Enviar"
          onClick={onSubmit}
          loading={isLoading}
          disabled={isDisabled}
        />
      </Grid>
    </Grid>
  );
}
