import React, { useState, useEffect } from "react";
import TabPanelContent from "./TabPanelContent";
import { getBudgets, createBudget, deleteBudget } from "../../../../api/budget";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { toast } from "react-toastify";

export default function BudgetPanel({ patientId }) {
  const [budgets, setBudgets] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoadingTabs, setIsLoadingTabs] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getBudgets(patientId);
        setBudgets(result);
      } catch (error) {
        toast.error("Erro ao obter orçamento");
      }
    };
    fetchData();
  }, [patientId]);

  const handleCreateBudget = async (title) => {
    setIsLoading(true);
    try {
      const res = await Promise.all([
        createBudget(patientId, title),
        getBudgets(patientId),
      ]);
      setBudgets(res[1]);
      setOpenDialog(false);
    } catch (e) {
      toast.error("Erro ao criar orçamento");
    }
    setIsLoading(false);
  };

  const handleDeleteBudget = async (budgetId) => {
    setIsLoadingTabs(true);
    try {
      await deleteBudget(budgetId);
      const res = await getBudgets(patientId);
      setBudgets(res);
      setTabIndex(0);
      toast.success("Orçamento removido");
    } catch (error) {
      toast.error("Erro ao deletar orçamento");
    }
    setIsLoadingTabs(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} style={{ marginTop: "12px", textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => setOpenDialog(true)}
              startIcon={<Add />}
            >
              Novo
            </Button>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            {!budgets?.length ? (
              <Typography>
                Nenhum orçamento criado. Clique no botão para inserir um
                orçamento.
              </Typography>
            ) : !isLoadingTabs ? (
              <Tabs
                style={{ marginTop: "12px" }}
                selectedIndex={tabIndex}
                onSelect={(index) => {
                  setTabIndex(index);
                }}
              >
                <TabList>
                  {budgets.map((budget) => (
                    <Tab key={budget._id}>{budget.name}</Tab>
                  ))}
                </TabList>
                {budgets.map((budget) => (
                  <TabPanel key={budget._id}>
                    <TabPanelContent
                      budgetId={budget._id}
                      handleDeleteBudget={handleDeleteBudget}
                    />
                  </TabPanel>
                ))}
              </Tabs>
            ) : null}
          </Grid>
        </Grid>
      </Container>

      <NewBudgetDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSubmit={handleCreateBudget}
        isLoading={isLoading}
      />
    </>
  );
}

function NewBudgetDialog({ open, onClose, onSubmit, isLoading }) {
  const [title, setTitle] = useState("");

  const handleSubmit = () => {
    onSubmit(title);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Novo orçamento</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          label="Título"
          fullWidth
          onChange={(e) => setTitle(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disableElevation onClick={onClose}>
          Fechar
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Criar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
