import React from 'react';
import FroalaEditor from "react-froala-wysiwyg";

export default function EditorFroala({ model, onModelChange }) {
  return <FroalaEditor
    tag="textarea"
    model={model}
    onModelChange={onModelChange}
  />
}
